<template>
  <!-- 角色管理 -->
  <div>
    <div class="nav">
      <div>
        <!-- 查询 -->
        <div>
          <div class="search_box">
            <div class="search_main">
              <div class="status">
                <div class="text">下单时间</div>
                <div>
                  <el-date-picker v-model="datatime" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd 00:00:00">
                  </el-date-picker>
                </div>
              </div>
            </div>
            <div class="search_main">
              <div class="status">
                <div class="text">订单状态</div>
                <div>
                  <el-select v-model="queryinfo.orderStatus">
                    <el-option value="" label="全部"></el-option>
                    <el-option v-for="(item, index) in orderStatus" :key="index" :label="item" :value="index">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <br />
            <div class="search_main">
              <div class="status">
                <div class="text">子订单ID</div>
                <div>
                  <el-input placeholder="请输入内容" v-model="queryinfo.id" style="width: 260px"></el-input>
                </div>
              </div>
            </div>
            <div class="search_main">
              <div class="status">
                <div class="text">总订单号</div>
                <div>
                  <el-input placeholder="请输入内容" v-model="queryinfo.combineOrderNo" style="width: 260px"></el-input>
                </div>
              </div>
            </div>
            <div class="search_main">
              <div class="status">
                <div class="text" style="width: 160px; margin-left: -70px">
                  收货人手机号
                </div>
                <div>
                  <el-input v-model="queryinfo.receiverPhone" placeholder="请输入内容" style="width: 260px"></el-input>
                </div>
              </div>
            </div>
            <div class="search_main">
              <div class="status1" style="display: flex">
                <div class="demandBtn" @click="search">
                  <img src="../../assets/images/search.png" alt="" /> 查询
                </div>
                <div class="resetBtn" @click="reset">
                  <img src="../../assets/images/reset.png" alt="" />重置
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 查询 -->
      </div>
    </div>
    <div style="height: 20px"></div>
    <div class="body" style="height: 560px">
      <!-- 列表 -->
      <div class="dataTable">
        <el-table ref="multipleTable" :data="tableData" border tooltip-effect="dark" style="width: 100%; font-family: PingFangRoutine" :header-cell-style="tableHeaderColor" :cell-style="{ color: '#2C2C2C', fontSize: '14px' }">
          <el-table-column prop="id" label="子订单ID" align="center" width="180" />
          <el-table-column prop="combineOrderNo" label="总订单号" align="center" width="180" />
          <el-table-column prop="orderNo" label="子订单号" align="center" width="180" />
          <el-table-column prop="freshStore.name" label="商家" align="center" width="180" />
          <el-table-column prop="freshOrderCombine.receiverName" label="收货人" align="center" width="120"/>
          <el-table-column prop="freshOrderCombine.receiverPhone" label="收货人手机号" align="center"  width="120">
          </el-table-column>
          <el-table-column prop="totalAmountStr" label="货物金额" align="center" width="120">
            <template #default="scope">￥{{ commonUtils.accMul(scope.row.storeAmount,0.01 )}}</template>
          </el-table-column>
          <el-table-column prop="totalAmountStr" label="订单金额" align="center" width="120">
            <template #default="scope">￥{{ commonUtils.accMul(scope.row.payAmount ,0.01) }}</template>
          </el-table-column>
          <el-table-column prop="forbidden" label="订单状态" align="orderStatus" width="120">
            <template slot-scope="scope">
              <span v-show="scope.row.orderStatus == 1 && !scope.row.cancelReason">已取消</span>
              <el-popover style="height: 80px" v-show="scope.row.orderStatus == 1 && scope.row.cancelReason" placement="bottom" width="150" trigger="hover">
                <div style="background-color: transparent" slot="reference">
                  {{ fn(scope.row.orderStatus) }}
                </div>
                <span>{{ scope.row.cancelReason }}</span>
              </el-popover>
              <div v-show="scope.row.orderStatus !== 1" :class=" scope.row.orderStatus == 0 ? 'red' : scope.row.orderStatus == 2 ? 'red' : 'black'" v-for="(item, index) in orderStatus">
                <span v-show="scope.row.orderStatus == index">
                  {{ scope.row.orderStatus == index ? item : "" }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="下单时间" align="center" width="170" />
          <el-table-column prop="paymentTime" label="支付时间" align="center" width="170" />
          <el-table-column prop="pickTime" label="提货时间" align="center" width="170" />
          <el-table-column prop="packTime" label="打包时间" align="center" width="170" />
          <el-table-column prop="unPickTime" label="退货时间" align="center" width="170" />
          <el-table-column prop="deliveryTime" label="发货时间" align="center" width="170" />
          <el-table-column prop="receiveTime" label="收货时间" align="center" width="170" />
          <el-table-column prop="refundTime" label="退款时间" align="center" width="170" />
          <el-table-column prop="settleTime" label="结算时间" align="center" width="170" />
          <el-table-column prop="transferTime" label="分账时间" align="center" width="170" />



          <el-table-column label="操作" align="center" class-name="small-padding fixed-width"  fixed="right">
            <template #default="scope">
              <div style="display: flex; justify-content: center">

                <el-button type="text" @click="orderdeta(scope.row)" >详情</el-button>

                <el-popover style="margin-left: 10px;" ref="popover" placement="bottom" :width="175" trigger="click" title="商品">
                  <div v-for="(item, index) in goodssketchs" :key="index" class="goodssketch">
                    {{ item }}
                  </div>
                  <el-button type="text" @click="selectStyle(scope.row)" slot="reference">明细</el-button>
                </el-popover>

              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 列表 -->
    </div>
    <div style="height: 10px"></div>
    <div class="footer">
      <!-- 分页 -->
      <div class="Pagination">
        <div class="Pagination_amin">
          <el-pagination background @current-change="handleCurrentChange" :current-page="1" :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
        <div class="Pagination_btn" style="height: 28px">确定</div>
      </div>
      <!-- 分页 -->
    </div>


    <!-- 确认订单 -->
  </div>
</template>
<script>
import {orderSubList} from "@/api/order";
import "../../font-style/font.css";
export default {
  data() {
    return {
      visible: false,
      datatime: [], //日期
      goodssketch: "",
      goodssketchs: [],
      orderStatus: ["待支付", "已取消", "待发货", "已发货", "待评价", "已完成"],
      queryinfo: {
        startDate: "",
        endDate: "",
        createTime: "",
        sourceType: "",
        memberMobile: "",
        orderStatus: "",
        combineOrderNo: "",
        currPage: "",
      },
      tableData: [],
      total: null
    };
  },
  components: {
  },
  activated() {
    this.list();
  },
  methods: {
    fn(status) {
      return this.orderStatus[status];
    },
    //订单列表
    list() {
      orderSubList(this.queryinfo).then((res) => {
        this.tableData = res.data.records;
        this.total = res.data.total * 1;
      });

    },
    // 分页
    handleCurrentChange(val) {
      this.queryinfo.currPage = val;
      this.list();
    },
    // table
    tableHeaderColor({ rowIndex }) {
      if (rowIndex === 0) {
        return "background-color: rgba(246, 248, 249, 1);color: #333333;;fontSize: '14px'";
      }
    },
    //搜索
    search() {
      this.queryinfo.startTime = this.datatime[0];
      this.queryinfo.endTime = this.datatime[1];
      this.queryinfo.currPage = ""
      this.total = 0
      this.list();
    },
    // 重置
    reset() {
      this.total=0
      this.datatime = [];
      this.queryinfo.sourceType = ""
      this.queryinfo.memberMobile = ""
      this.queryinfo.orderStatus = ""
      this.queryinfo.combineOrderNo = ""
      this.queryinfo.receiverPhone = ""
      this.queryinfo.currPage = ""
      this.queryinfo.startTime = "";
      this.queryinfo.endTime = "";
      this.list();
    },
    //详情页
    orderdeta(row) {
      this.$router.push({
        path: "/order/detail",
        query: {
          id: row.combineOrderId,
          subId: row.id,
          orderStatus: row.orderStatus,
        },
      });
    },
    // 商品简述
    selectStyle(row) {
      this.goodssketchs = [];
      row.freshOrderGoodsList.forEach((item, index) => {
          if (index <= 4) this.goodssketchs.push(item.name+","+item.goodsNum+","+item.quality+""+item.basicUnit);
      });
    }
  },
};
</script>
<style lang="less" scoped>
@import "../../assets/css/common.less";

/deep/ .el-table__cell.is-center {
  text-align: left;
}

/deep/ .cell {
  text-align: center;
}

#el-popover-2419 {
  width: 220px !important;
  z-index: 9999;
}

.operabtn {
  width: 60px;
  height: 30px;
  background: #279dfb;
  border-radius: 8px;
  text-align: center;
  line-height: 30px;
  margin-right: 0;

  img {
    margin-top: 7px;
    width: 18px;
    height: 16px;
  }
}

.xinxibtn {
  width: 60px;
  height: 30px;
  background: #fc9714;
  border-radius: 8px;
  text-align: center;
  line-height: 30px;
  margin-left: 10px;

  img {
    margin-top: 7px;
    width: 18px;
    height: 16px;
  }
}

.delete {
  width: 60px;
  height: 30px;
  background: #fb6a57;
  border-radius: 8px;
  text-align: center;
  line-height: 30px;
  margin-left: 10px;

  i {
    font-size: 18px;
    color: white;
    margin-top: 6px;
    width: 18px;
    height: 16px;
  }
}

.quxiaobtn {
  width: 60px;
  height: 30px;
  background: #ffcc33;
  border-radius: 8px;
  text-align: center;
  line-height: 30px;
  margin-left: 10px;

  img {
    margin-top: 7px;
    width: 18px;
    height: 16px;
  }
}

.querenbtn {
  width: 60px;
  height: 30px;
  background: #18bea4;
  border-radius: 8px;
  text-align: center;
  line-height: 30px;
  margin-left: 10px;

  img {
    margin-top: 7px;
    width: 18px;
    height: 16px;
  }
}

.status > .text:nth-child(1) {
  width: 57px;
  text-align: right;
}

.status > .text:nth-child(2) {
  margin-left: 19px;
}
</style>
