<template>
  <el-card style="margin-top: 10px">
    <div class="itemPut">
      <el-form label-width="160px" :label-position="labelPositiona" :rules="storeFrom.tabeType=='1'?ruleStore:(storeFrom.tabeType=='2'?rulsc:(storeFrom.tabeType=='3'?rulst:rules))" ref="storeFrom" :model="storeFrom">

        <el-tabs v-model="storeFrom.tabeType" >
          <el-tab-pane label="门店信息" name="1">
    <!--      <div class="headline" style="margin-top: 20px">门店信息</div>-->
    <!--      <div class="across"></div>-->
          <div style="margin: 20px"></div>
            <el-form-item label="注册号码：" style="margin-left: 70px" prop="mobile">
              <el-input v-model="storeFrom.mobile"></el-input>
            </el-form-item>
            <el-form-item label="店铺名称：" style="margin-left: 70px" prop="name">
              <el-input v-model="storeFrom.name"></el-input>
            </el-form-item>
            <el-form-item label="店铺详情地址：" style="margin-left: 70px" prop="address">
              <el-input v-model="storeFrom.address"></el-input>
            </el-form-item>
            <el-form-item label="门头照：" style="margin-left: 70px" prop="">
              <el-upload name="image" :action="imageFile" list-type="picture-card" :on-remove="handleRemove"
                :on-success="frontImg" :file-list="fileListFront" :headers="headers" :on-preview="handlePictureCardPreview"
                :limit="1">
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible" :modal-append-to-body="false">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </el-form-item>
            <el-form-item label="店内照：" style="margin-left: 70px" prop="">
              <el-upload name="image" :action="imageFile" list-type="picture-card" :on-remove="handleRemove"
                :on-success="innerImg" :file-list="fileListInner" :headers="headers" :on-preview="handlePictureCardPreview"
                :limit="1">
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible" :modal-append-to-body="false">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </el-form-item>

            <el-form-item label="联系人：" style="margin-left: 70px" prop="contact">
              <el-input v-model="storeFrom.contact"></el-input>
            </el-form-item>
            <el-form-item label="联系电话：" style="margin-left: 70px" prop="phone">
              <el-input v-model="storeFrom.phone"></el-input>
            </el-form-item>

            <el-form-item label="分店数量：" style="margin-left: 70px" prop="subCount">
              <el-input type="number" oninput="if(value>999)value=999" v-model="storeFrom.subCount"></el-input>
            </el-form-item>
            <el-form-item label="合作期限：" style="margin-left: 80px" prop="period">
              <el-select style="width: 250px; margin-left: -10px" v-model="storeFrom.period">
                <el-option label="一年" value="1"></el-option>
                <el-option label="二年" value="2"></el-option>
                <el-option label="三年" value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="BD邀请码：" style="margin-left: 70px" prop="invokeCode">
              <el-input v-model="storeFrom.invokeCode"></el-input>
            </el-form-item>


          <el-form-item label="商户号：" style="margin-left: 70px" prop="subMchid">
            <el-input v-model="storeFrom.subMchid"     :disabled="true"></el-input>
          </el-form-item>

            <el-form-item label="签约链接：" style="margin-left: 70px" prop="signUrl">
              <el-input   type="textarea" v-model="storeFrom.signUrl"   :disabled="true"></el-input>
            </el-form-item>

          <el-form-item label="签约状态：" prop="subMchStatus"  style="margin-left: 70px">
            <el-radio-group v-model="storeFrom.subMchStatus" :disabled="true">
              <el-radio :label="1">待体检</el-radio>
              <el-radio :label="2">审核中</el-radio>
              <el-radio :label="3">待签约</el-radio>
              <el-radio :label="4">已驳回</el-radio>
              <el-radio :label="5">待账户验证</el-radio>
              <el-radio :label="6">完成</el-radio>
              <el-radio :label="7">冻结</el-radio>
              <el-radio :label="-1">已作废</el-radio>
            </el-radio-group>
          </el-form-item>

        </el-tab-pane>
          <el-tab-pane label="资质信息" name="2">
      <!--      <div class="headline">资质信息</div>-->
      <!--        <div class="across" style="margin-bottom: 20px"></div>-->
              <div style="margin-left: 70px">
                <el-form-item label="主体类型：" prop="licenseData.type">
                  <el-radio-group v-model="storeFrom.licenseData.type" @change="cervicaltrue(storeFrom.licenseData.type)">
                    <el-radio :label="2">企业</el-radio>
                    <el-radio :label="4">个体经商户</el-radio>
                    <el-radio :label="2500">个人卖家</el-radio>
                  </el-radio-group>
                </el-form-item>
                <div>
                  <el-form-item label="开店人身份信息：" ref="uploadElement" prop="file">
                    <div>面部在前国徽在后</div>
                    <el-upload style="margin-left: -10px" multiple :limit="2" :action="idcardFile" list-type="picture-card"
                      :on-remove="handleRemove" :on-change="checkFile" :auto-upload="true" :name="upName"
                      :delete="deleteUserimg" ref="upload" :on-exceed="handleExceed" :file-list="sideFileList"
                      :on-preview="handlePictureCardPreview">
                      <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible" :modal-append-to-body="false">
                      <img width="100%" :src="dialogImageUrl" alt="" />
                    </el-dialog>
                  </el-form-item>
                  <el-form-item label="姓名：" prop="licenseData.realName" :rules="[ { required: true, message: '请填写姓名', trigger: 'blur'}]" >
                    <el-input v-model="storeFrom.licenseData.realName"></el-input>
                  </el-form-item>
                  <el-form-item label="身份证：" prop="licenseData.cardNo"  :rules="[{ required: true, validator: validateIdNumber, trigger: 'blur' }]" >
                    <el-input v-model="storeFrom.licenseData.cardNo"></el-input>
                  </el-form-item>
                  <el-form-item label="身份证地址：" prop="licenseData.userAddress" :rules="[{ required: true, message: '请填写身份证地址', trigger: 'blur' }]" >
                    <el-input v-model="storeFrom.licenseData.userAddress"></el-input>
                  </el-form-item>
                  <el-form-item label="有效期限：" prop="licenseData.userIdCope">
                    <el-radio-group v-model="userIdCope" @change="cardNoStatus()">
                      <el-radio :label="1">长期有效</el-radio>
                      <el-radio :label="2">固定有效期</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <div v-if="userIdCope == '1'">
                    <el-form-item prop="licenseData.validityStart" :rules="[{ required: true, message: '请填写开始日期' }]">
                      <el-date-picker v-model="storeFrom.licenseData.validityStart" type="date" value-format="yyyy-MM-dd"
                        placeholder="开始日期">
                      </el-date-picker>
                    </el-form-item>
                  </div>
                  <div v-if="userIdCope == '2'">
                    <el-form-item prop="licenseData.validityStart" :rules="[ { required: true, message: '请填写开始日期', trigger: 'blur', }, ]">
                      <el-date-picker v-model="storeFrom.licenseData.validityStart" type="date" placeholder="开始日期"
                        value-format="yyyy-MM-dd">
                      </el-date-picker>
                    </el-form-item>
                    <el-form-item prop="licenseData.validityEnd" :rules="[ { required: true, message: '请填写结束日期', trigger: 'blur', }, ]">
                      <el-date-picker v-model="storeFrom.licenseData.validityEnd" type="date" value-format="yyyy-MM-dd"
                        placeholder="结束日期">
                      </el-date-picker>
                    </el-form-item>
                  </div>
                </div>
                <div v-if="storeFrom.licenseData.type !== 2500&&storeFrom.tabeType=='2'">
                  <el-form-item label="营业执照：" prop="licenseData.licenseImg" ref="uploads">
                    <el-upload name="licenseImg" :action="licenseFile" list-type="picture-card" :on-remove="licenseRemove"
                      :on-success="licenseImg" :limit="1" :on-exceed="exceed" :headers="headers"
                      :on-preview="handlePictureCardPreview" :before-upload="beforeAvatarUpload" :on-change="changeLicense"
                      :file-list="fileListLicense">
                      <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible" :modal-append-to-body="false">
                      <img width="100%" :src="dialogImageUrl" alt="" />
                    </el-dialog>
                  </el-form-item>
                  <el-form-item label="注册号：" prop="licenseData.certNo" :rules="[ { required: true, message: '请填写营业执照注册号', trigger: 'blur', }, ]">
                    <el-input v-model="storeFrom.licenseData.certNo"></el-input>
                  </el-form-item>
                  <el-form-item label="公司名称：" prop="licenseData.name" :rules="[ { required: true, message: '请填写商户名称', trigger: 'blur' }, ]">
                    <el-input v-model="storeFrom.licenseData.name"></el-input>
                  </el-form-item>
                  <el-form-item label="注册地址：" prop="licenseData.address" :rules="[ { required: true, message: '请填写注册地址', trigger: 'blur' }, ]">
                    <el-input v-model="storeFrom.licenseData.address"></el-input>
                  </el-form-item>
                  <el-form-item label="法定代表人：" prop="licenseData.legalRepName" :rules="[ { required: true, message: '请填写法人姓名', trigger: 'blur' }, ]">
                    <el-input v-model="storeFrom.licenseData.legalRepName"></el-input>
                  </el-form-item>
                  <el-form-item label="营业期限：" v-if="storeFrom.licenseData.type == 2" prop="licenseData.scope">
                    <el-radio-group v-model="storeFrom.licenseData.scope" @change="businesstrue(storeFrom.licenseData.scope)">
                      <el-radio name="2" :label="1">长期有效</el-radio>
                      <el-radio name="2" :label="2">固定有效期</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <div v-if="storeFrom.licenseData.scope == 1 && storeFrom.licenseData.type == 2&&storeFrom.tabeType=='2'">
                    <el-form-item prop="licenseData.validTermStart" :rules="[{ required: true, message: '请填写开始日期' }]">
                      <el-date-picker v-model="storeFrom.licenseData.validTermStart" type="date" value-format="yyyy-MM-dd"
                        placeholder="开始日期">
                      </el-date-picker>
                    </el-form-item>
                  </div>
                  <div v-if="storeFrom.licenseData.scope == 2 && storeFrom.licenseData.type == 2&&storeFrom.tabeType=='2'">
                    <el-form-item prop="licenseData.validTermStart" :rules="[ { required: true, message: '请填写开始日期', trigger: 'blur', }, ]">
                      <el-date-picker v-model="storeFrom.licenseData.validTermStart" type="date" placeholder="开始日期"
                        value-format="yyyy-MM-dd">
                      </el-date-picker>
                    </el-form-item>
                    <el-form-item prop="licenseData.validTermEnd" :rules="[ { required: true, message: '请填写结束日期', trigger: 'blur', }, ]">
                      <el-date-picker v-model="storeFrom.licenseData.validTermEnd" type="date" value-format="yyyy-MM-dd"
                        placeholder="结束日期">
                      </el-date-picker>
                    </el-form-item>
                  </div>
                  <div style="margin-left: 10px">
                    <el-form-item label="其他资质：" prop="licenseData.licenseAddition">
                      <el-upload name="image" :action="privacy" list-type="picture-card" :on-remove="aptitudeRemove"
                        :on-success="aptitudeFile" :headers="headers" :on-preview="handlePictureCardPreview" :limit="9"
                        :file-list="fileListAddition" :before-upload="beforeAvatarUpload" :multiple="true">
                        <i class="el-icon-plus"></i>
                      </el-upload>
                      <el-dialog :visible.sync="dialogVisible" :modal-append-to-body="false">
                        <img width="100%" :src="dialogImageUrl" alt="" />
                      </el-dialog>
                    </el-form-item>
                  </div>
                </div>
              </div></el-tab-pane>
          <el-tab-pane label="结算信息" name="3">
      <!--      <div class="headline">结算信息</div>-->
      <!--        <div class="across" style="margin-bottom: 20px"></div>-->
              <el-form-item label="账户类型" style="margin-left: 70px" prop="bankNae">
                <el-radio-group v-model="storeFrom.accountData.accountType">
                  <el-radio :label="74">对公账户</el-radio>
                  <el-radio :label="75">对私账户</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="开户行省市区：" style="margin-left: 70px" prop="">
                <el-select v-model="storeFrom.accountData.province" @change="selectProvince" placeholder="请选择">
                  <el-option v-for="item, index in options" :key="index" :label="item.name" :value="item.value">
                  </el-option>
                </el-select>
                <el-select v-model="storeFrom.accountData.city" @visible-change="showCity" @change="selectCity"
                  placeholder="请选择">
                  <el-option v-for="item, index in cityList" :key="index" :label="item.name" :value="item.value">
                  </el-option>
                </el-select>
                <el-select v-model="storeFrom.accountData.district" @visible-change="showDistrict" placeholder="请选择">
                  <el-option v-for="item, index in districtList" :key="index" :label="item.name" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="开户行：" style="margin-left: 70px" prop="">
                <el-input v-model="storeFrom.accountData.bankName">
                  <el-button slot="append" icon="el-icon-search" @click="searchBank"></el-button>
                </el-input>
                <el-card class="box-card" v-if="searchBoxshow">
                  <i v-if="bankList.length == 0" class="el-icon-loading"></i>
                  <div v-for="(item, index) in bankList " :key="index" class="text item" @click="optforBank(item)">
                    <p>{{ item.bankAlias }}</p>
                  </div>
                </el-card>
              </el-form-item>
              <el-form-item label="开户支行省市" style="margin-left: 70px" prop="" v-if="storeFrom.accountData.needBranch">
                <el-select v-model="storeFrom.accountData.subProvince" @change="ceshi" placeholder="请选择">
                  <el-option v-for="item, index in subOptions" :key="index" :label="item.provinceName" :value="item.value">
                  </el-option>
                </el-select>
                <el-select v-model="storeFrom.accountData.subCity" @change="subCitySle" placeholder="请选择">
                  <el-option v-for="item, index in subCitylist" :key="index" :label="item.provinceName" :value="item.value">
                  </el-option>
                </el-select>
                <el-select v-model="storeFrom.accountData.branchBank" placeholder="请选择">
                  <el-option v-for="item, index in subBank" :key="index" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="银行卡号：" style="margin-left: 70px" prop="accountData.bankCardNo">
                <el-input v-model="storeFrom.accountData.bankCardNo"></el-input>
              </el-form-item>
              <el-form-item label="银行预留手机号：" style="margin-left: 70px" prop="accountData.reservationMobile">
                <el-input v-model="storeFrom.accountData.reservationMobile"></el-input>
              </el-form-item></el-tab-pane>
          <el-tab-pane label="提现信息" name="4">

            <el-form-item label="可提现额度（元）：" style="margin-left: 70px" prop="walletBalance">
              <el-input v-model="walletBalance"></el-input>
            </el-form-item>
            <el-form-item label="冻结额度（元）：" style="margin-left: 70px" prop="walletBalance">
              <el-input v-model="pendingAmount"></el-input>
            </el-form-item>
            <el-form-item label="提现金额（元）：" style="margin-left: 70px" prop="money">
              <el-input v-model="money"></el-input>
            </el-form-item>

          </el-tab-pane>
        </el-tabs>


      </el-form>

      <div v-if="storeFrom.tabeType !=4" slot="footer" style="display: flex; justify-content: center">
        <router-link to="/store/list"><el-button style="margin-left: 10px">返 回</el-button></router-link>
        <el-button type="primary" style="margin-left: 10px" @click="storeAdd('storeFrom', 'licenseFrom', 'accountFrom')">保存</el-button>
        <el-button v-if="storeFrom.status == 0 && storeFrom.subMchStatus ==6 " type="success" style="margin-left: 10px" @click="changeStatus('1')">通 过</el-button>
        <el-button v-if="storeFrom.status == 0 " type="warning" style="margin-left: 10px" @click="changeStatus('2')">拒 绝</el-button>
        <el-button v-if="storeFrom.subMchStatus !==6 && storeFrom.subMchStatus !==3" type="success" style="margin-left: 10px" @click="toGoWechat()">提交微信支付进件</el-button>
        <el-button v-if="storeFrom.subMchStatus >=2 " type="success" style="margin-left: 10px" @click="WechatStatus()">查询提交状态</el-button>
        <el-button v-if="storeFrom.subMchStatus ==3 " type="success" style="margin-left: 10px" >推送新消息</el-button>
      </div>

      <div v-if="storeFrom.tabeType ==4" slot="footer" style="display: flex; justify-content: center">
        <router-link to="/store/list"><el-button style="margin-left: 10px">返 回</el-button></router-link>
        <el-button type="primary" style="margin-left: 10px" @click="getWalletBalance" >查询可提现金额</el-button>
        <el-button type="primary" style="margin-left: 10px" @click="cashOut" >发起提现</el-button>
      </div>



    </div>
  </el-card>
  <!-- 添加 -->
</template>
<script>
import * as storeApi from "@/api/store";

let id = 0;
let cc = [];
import url from "@/utils/file";
import { ocrIdcard } from "@/api/file";
import { getStore } from "@/utils/storage";
import {
  storeEdit,
  storeDetail,
  uploadGetStsUrl,
  BankAccounts,
  getRegionApi,
  getwxCapitalbanks,
  getwxCapitalProvinces,
  getwxCapitalCity,
  getwxCapitalbranches,
  changeStatus,
  toWechat,
  getWechatStaus,
  getWalletBalance, cashOut
} from "@/api/store";
import { createLogger } from "vuex";

export default {
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else {
        const reg = /^1[3-9][0-9]\d{8}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    var validateIdNumber = (rule, value, callback) => {
      const idNumberReg =
        /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
      if (!value) {
        return callback(new Error("请填写身份证号"));
      }
      setTimeout(() => {
        if (!idNumberReg.test(value)) {
          return callback(new Error("您的身份证号码格式错误!"));
        } else {
          callback();
        }
      }, 100);
    };
    var bankCards = (rule, value, callback) => {
      const idNumberReg = /^([1-9]{1})(\d{14}|\d{15}|\d{16}|\d{17}|\d{18})$/;
      if (!value) {
        return callback(new Error("请填写银行卡号"));
      }
      setTimeout(() => {
        if (!idNumberReg.test(value)) {
          return callback(new Error("您的银行卡号码格式错误!"));
        } else {
          callback();
        }
      }, 100);
    };
    var subCount = (rule, value, callback) => {
      const idNumberReg = /^[+]{0,9}(\d+)$/;
      if (!value) {
        return callback(new Error("请输入分店数量"));
      }
      setTimeout(() => {
        if (!idNumberReg.test(value)) {
          return callback(new Error("您的分店数量格式错误!"));
        } else {
          callback();
        }
      }, 100);
    };
    var validateImg = (rule, value, callback) => { };

    return {
      money: "点击查询加载最新额度",
      walletBalance: "点击查询加载最新额度",
      pendingAmount: "点击查询加载最新额度",
      labelPositiona: "right",
      dialogVisible: false,
      cervical: true, //主体类型隐藏
      scope: 2, //判断营业期限条件
      business: true, //营业期限
      businesss: false, //营业期限
      cardNoperiod: 2, //判断身份证有效期限条件
      cardNoperiods: true, //份证有效期限
      cardNoperiodss: false, //份证有效期限
      supervisor: false, //管理员信息同步 按钮
      idcardInfo: "", //身份证识别信息
      upName: "frontImg", //上传身份证正反标识
      bankList: [],
      cityList: [], //城市
      districtList: [],
      num: 0, //计数
      regionList: ['北京市', '北京市', '大兴区'],
      defaultValue: ['北京市', '北京市', '大兴区'],
      subProvince: '',
      subCity: '',
      subBank: '',
      subBankValue: '',
      subCitylist: [],
      subBoxshow: false,
      searchBoxshow: false, //搜索框的显示
      leaf: false,
      checkedNodes: [],
      props: {
        lazy: true,
        children: "children",
        lazyLoad(node, resolve) {
          let { cont } = [];
          let { level } = [];

          getRegionApi({ pId: node.data.id }).then((data) => {
            console.log(data, node, resolve);
            // const list = JSON.parse(JSON.stringify(data.data));
            data.data.forEach((item, index) => {
              console.log(item, 6767);
              item.label = item.name;
              item.value = item.name;
              item.children = [];
            });
            id++;
            level = data.data;
            const nodes = Array.from(level).map((item) => ({
              ...item,
              leaf: id >= 2,
            }));

            console.log(nodes, 65);

            cont = data.data;
            if (id == 2) {
              cc = nodes;
            }
            console.log(cont, 85);
            resolve(nodes);
          });
        },
      },
      subOptions: [],//微信支持的省份
      options: [],
      subCitylist: [],//微信支持的城市
      userIdCope: 1, //身份证
      storeFrom: {
        tabeType:'1',
        period: "1", //合作期限
        frontImg: [], //门头照
        innerImg: [], //店内照
        storeInfo:{
          mobile:'',
        },
        licenseData: {
          certNo: "", //注册号
          name: "", //商户名称
          address: "", //住所
          legalRepName: "", //法人
          validTermStart: "", //营业结束日期
          validTermEnd: "", //营业开始日期
          type: '',
          scope: 2,
          licenseAddition: [], //其他资质
          cardFrontSide: "", //身份证正面
          cardReverseSide: "", //身份证反面
          validityEnd: "", //身份证有效期结结束
          validityStart: "", //身份证有效期开始
          bankCardNo: "", //银行卡号
          cardNo: "", //身份证号
          realName: "", //开店人姓名
          userAddress: '',
        },
        accountData: {
          province: '', //省
          branchBank: '', //支行银行
          branchBankCode: '',//支行银行code
          cityCode: '',//城市code
          districtCode: '',//区code
          subCity: '',//支行城市
          subCityCode: '',//支行市code
          subProvince: '',//支行省
          subProvinceCode: '',//支行省code

          city: '',//市
          district: '',//省
          radio: "74", //账户类型
          address: "", //详情地址
          bankCardNo: "", //银行卡号
          bankName: "", //开户行
          birthDay: "", //出生日期
          cardFrontSide: "", //身份证正面
          cardNo: "", //身份证号
          cardReverseSide: "", //身份证反面
          createTime: "", //创建时间
          gender: "", //性别
          id: 0, //主键id
          issuedBy: "", //发证机关
          nation: "", //民族
          realName: "", //结算人姓名
          reservationMobile: "", //预留电话
          storeId: 0, //店铺id
          validityEnd: "", //身份证有效期结结束
          validityStart: "", //身份证有效期开始
          verifyId: "", //e签宝业务id
          needBranch: false,
        },
      },
      imageFile: url.url_image, //上传image
      licenseFile: url.url_license, //营业执照
      idcardFile: url.url_idcard, //身份证
      privacy: url.url_privacy, //资质上传
      rulst:{
        bankCardNo: [
            { required: true, validator: bankCards, trigger: "blur" },
          ],
          reservationMobile: [
            { required: true, validator: checkPhone, trigger: "blur" },
          ],
      },
      rulsc:{
        validTermStart:[{ required: true, message: '请填写开始日期' }],
        userAddress: [{ required: true, message: "请填写身份证地址", trigger: "blur" },
          ],
          cardNo: [
            { required: true, validator: validateIdNumber, trigger: "blur" },
          ],
          licenseImg: [
            { required: true, message: "请上传营业执照", trigger: "change" },
          ],
          legalRepName: [
            { required: true, message: "请填写法人姓名", trigger: "blur" },
          ],
          realName:[
            { required: true, message: "请填写姓名", trigger: "blur" },
          ],
          certNo: [
            {
              required: true,
              message: "请填写营业执照注册号",
              trigger: "blur",
            },
          ],
          name: [
            { required: true, message: "请填写商户名称", trigger: "blur" },
          ],
          scope: [
            { required: true, message: "请选择营业期限", trigger: "change" },
          ],

          type: [
            { required: true, message: "请选择主体类型", trigger: "change" },
          ],
      },
      ruleStore:{
        mobile: [{ required: true, validator: checkPhone, trigger: "blur" }],
          name: [{ required: true, message: "请填写店铺名称", trigger: "blur" }],
          address: [
            { required: true, message: "请填写店铺地址", trigger: "blur" },
          ],
          frontImg: [
            { required: true, message: "请上传门头照", trigger: "change" },
          ],
          innerImg: [
            { required: true, message: "请上传店内照", trigger: "change" },
          ],
          contact: [
            { required: true, message: "请填写店铺联系人", trigger: "blur" },
          ],
          phone: [{ required: true, validator: checkPhone, trigger: "blur" }],
          subCount: [{ required: true, validator: subCount, trigger: "blur" }],
          invokeCode: [
            { required: true, message: "请填写BD邀请码", trigger: "blur" },
          ],
      },
      rules: {
        userIdCope: [
          {
            required: true,
            message: "请选择证件有效期限",
            trigger: "change",
          },
        ],
        storeInfo: {
          mobile: [{ required: true, validator: checkPhone, trigger: "blur" }],
          name: [{ required: true, message: "请填写店铺名称", trigger: "blur" }],
          address: [
            { required: true, message: "请填写店铺地址", trigger: "blur" },
          ],
          frontImg: [
            { required: true, message: "请上传门头照", trigger: "change" },
          ],
          innerImg: [
            { required: true, message: "请上传店内照", trigger: "change" },
          ],
          contact: [
            { required: true, message: "请填写店铺联系人", trigger: "blur" },
          ],
          phone: [{ required: true, validator: checkPhone, trigger: "blur" }],
          subCount: [{ required: true, validator: subCount, trigger: "blur" }],
          invokeCode: [
            { required: true, message: "请填写BD邀请码", trigger: "blur" },
          ],
        },
        realName: [
          { required: true, message: "请填写姓名", trigger: "blur" },
        ],
        bankName: [
          { required: true, message: "请选择开户行", trigger: "blur" },
        ],
        accountData: {

          bankCardNo: [
            { required: true, validator: bankCards, trigger: "blur" },
          ],
          reservationMobile: [
            { required: true, validator: checkPhone, trigger: "blur" },
          ],
        },
        licenseData: {
          userAddress: [{ required: true, message: "请填写身份证地址", trigger: "blur" },
          ],
          cardNo: [
            { required: true, validator: validateIdNumber, trigger: "blur" },
          ],
          licenseImg: [
            { required: true, message: "请上传营业执照", trigger: "change" },
          ],
          legalRepName: [
            { required: true, message: "请填写法人姓名", trigger: "blur" },
          ],
          realName:[
            { required: true, message: "请填写姓名", trigger: "blur" },
          ],
          certNo: [
            {
              required: true,
              message: "请填写营业执照注册号",
              trigger: "blur",
            },
          ],
          name: [
            { required: true, message: "请填写商户名称", trigger: "blur" },
          ],
          scope: [
            { required: true, message: "请选择营业期限", trigger: "change" },
          ],

          type: [
            { required: true, message: "请选择主体类型", trigger: "change" },
          ],
        },
      },
      submitFile: {
        arrFile: [],
      },
      fileData: [],
      dialogImageUrl: "", //照片放大
      fileListFront: [], //门头图片回显
      fileListInner: [], //店内图片回显
      fileListAddition: [], //其他资质图片回显
      fileListLicense: [], //营业执照回显
      sideFileList: [], //身份证面部反面
      licenseImgNum: 0,
    };
  },
  created() {
    this.getInfo();
  },
  mounted() {
    getRegionApi().then((data) => {
      const list = JSON.parse(JSON.stringify(data.data));
      let cont = JSON.parse(JSON.stringify(data.data));
      data.data.forEach((item, index) => {
        cont[index].children = [];
        cont[index].label = item.name;
        cont[index].value = item.name;
      });
      this.options = cont;
    });
  },
  methods: {
    cashOut(){
      console.log(this.storeFrom);
      storeApi.cashOut(this.storeFrom.id,this.money).then((data)=>{
        if(data.status != 10000){
          this.$message.error(data.msg);
        }else {
          this.$message.success("提现成功");
          this.money = 0;
          getWalletBalance();
        }
      });
    },
    getWalletBalance(){
      console.log(this.storeFrom);
      storeApi.getWalletBalance(this.storeFrom.subMchid).then((data)=>{
        this.walletBalance = this.commonUtils.accMul(data.data.availableAmount,0.01);
        this.pendingAmount = this.commonUtils.accMul(data.data.pendingAmount,0.01);
        this.money = 0;
      });
    },
    //查询提交状态
    WechatStatus(){
      getWechatStaus({
        storeId:this.$route.query.id
      }).then((data)=>{
        if(data.data.applymentState == "REJECTED"){
          let text = "被驳回\r\n";
          for(var a in data.data.auditDetail){
            text += data.data.auditDetail[a].rejectReason + "\r\n";
          }
          this.$message.warning(text);
        }else {
          this.$message.warning(data.data.applymentStateDesc);
        }

        this.getInfo();
      })
    },
   //向微信提交进件
    toGoWechat(){
      toWechat({
        storeId:this.$route.query.id
      }).then((data)=>{
        console.log(data);
        if(data.status==10000){
          this.$message.warning('提交成功');
          this.getInfo();
        }
      })
    },
    async  changeStatus(type) {
      let title = "";
      let status = 0;
      if(type  == 1){
        title = "确定审核通过此店铺";
        status = 1;
      }else{
        title = "确定不通过此店铺";
        status = 2;
      }
      if (!(await this._confirm(title))) return;
      let id = this.$route.query.id;
      let msg  = "";
      await storeApi.changeStatus({id,status,msg}).then((res) => {
        this.$router.push("/store/list");
      });
    },
    showCity(v) {
      console.log(v);
      if (this.storeFrom.accountData.provinceCode !== '') {
        getRegionApi({
          pId: this.storeFrom.accountData.provinceCode
        }).then((data) => {
          console.log(data, '市区');
          data.data.forEach((item) => {
            item.label = item.name
            item.value = item.name
          })
          this.cityList = data.data
        })
      }

    },
    showDistrict(v) {
      console.log(v, this.storeFrom.accountData.cityCode);
      if (this.storeFrom.accountData.cityCode !== '') {
        getRegionApi({
          pId: this.storeFrom.accountData.cityCode
        }).then((data) => {
          console.log(data, '区');
          data.data.forEach((item) => {
            item.label = item.name
            item.value = item.name
          })
          this.districtList = data.data
        })
      }

    },

    selectCity(v) {
      let arr = this.cityList.filter((item) => {
        return item.label == v
      })
      console.log(arr, 65444);
      this.storeFrom.accountData.cityCode = arr[0].id
      getRegionApi({
        pId: this.storeFrom.accountData.cityCode
      }).then((data) => {
        console.log(data, '区');
        data.data.forEach((item) => {
          item.label = item.name
          item.value = item.name
        })
        this.districtList = data.data
      })
    },
    selectProvince(v) {
      console.log(this.storeFrom.accountData.province);
      console.log(v, 6868);
      let arr = this.options.filter((item) => {
        return item.label == v
      })
      console.log(arr, 748);
      this.storeFrom.accountData.provinceCode = arr[0].id
      getRegionApi({
        pId: this.storeFrom.accountData.provinceCode
      }).then((data) => {
        console.log(data, '市区');
        data.data.forEach((item) => {
          item.label = item.name
          item.value = item.name
        })
        this.cityList = data.data
        this.storeFrom.accountData.city = ''
        this.storeFrom.accountData.district = ''
      })
    },
    //选择支行
    subCitySle(v) {
      console.log(v);
      let arr = this.subCitylist.filter((item) => {
        return item.cityName == v
      })
      this.storeFrom.accountData.subCityCode = arr[0].cityCode
      console.log(this.storeFrom.accountData.branchBankCode, 51151);
      getwxCapitalbranches({
        bankAliasCode: this.storeFrom.accountData.branchBankCode,
        cityCode: this.storeFrom.accountData.subCityCode
      }).then((data) => {
        if (data.status == 500) {
          this.$message.error("当前城市没有所选开户行支行");
          this.subBank = []
        } else {
          console.log(data, '6868');
          data.data.forEach((item) => {
            item.label = item.bankBranchName
            item.value = item.bankBranchName
          })
          this.subBank = data.data
        }

      })
      console.log({
        bankAliasCode: this.storeFrom.accountData.branchBankCode,
        cityCode: this.storeFrom.accountData.subCityCode
      });
    },
    ceshi(v) {
      console.log(v, 868686);
      let arr = this.subOptions.filter((item) => {
        return item.value == v
      })
      this.storeFrom.accountData.subProvinceCode = arr[0].provinceCode
      getwxCapitalCity({
        provinceCode: this.storeFrom.accountData.subProvinceCode
      }).then((data) => {
        console.log(arr[0].provinceCode, 65);
        data.data.forEach((item) => {
          item.label = item.cityName
          item.value = item.cityName
        })
        this.subCitylist = data.data
        this.storeFrom.accountData.subCity = ''
        this.storeFrom.accountData.branchBank = ''
        this.storeFrom.accountData.subCityCode = ''
        this.subBank = []
      })
      console.log(arr, 'arr');
    },
    //选择开户行
    optforBank(v) {
      console.log(v);
      this.storeFrom.accountData.branchBankCode = v.bankAliasCode
      this.storeFrom.accountData.bankName = v.bankAlias
      this.searchBoxshow = false
      this.storeFrom.accountData.branchBank = ''
      this.storeFrom.accountData.subCity = ''
      this.storeFrom.accountData.subCityCode = ''
      this.storeFrom.accountData.subProvince = ''
      this.storeFrom.accountData.subProvinceCode = ''
      if (v.needBankBranch) {
        this.storeFrom.accountData.needBranch = true
        getwxCapitalProvinces().then((data) => {
          data.data.forEach((item) => {
            item.label = item.provinceName
            item.value = item.provinceName
          })

          this.subOptions = data.data
          console.log(data, 4445);

        })

      }
    },
    //获取银行列表
    searchBank() {
      if (this.storeFrom.accountData.bankName.length >= 2) {
        this.searchBoxshow = true
        getwxCapitalbanks({
          type: this.storeFrom.accountData.radio,
          key: this.storeFrom.accountData.bankName,
        }).then((data) => {

          this.bankList = data.data
          this.searchBoxshow = true
          console.log(data, 6868);
        });
      } else {
        this.$message.warning(
          '关键词需大于两个字'
        );
      }

    },
    getCheckedNodes(leafOnly) {
      console.log(leafOnly);
    },
    //省市区
    handleChange(v) {
      id = 0;
      console.log(cc, "map");
      let district = cc.filter((item) => {
        return item.name == this.regionList[2];
      });
      this.$keyMap.set("oa", this.regionList);
      console.log(this.regionList[2], 6);
    },
    //限定上传大小
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    //其他资质上传图片
    aptitudeFile(img) {
      this.storeFrom.licenseData.licenseAddition.push(a);
    },
    //其他资质删除图片
    aptitudeRemove(file) {
      let i = this.storeFrom.licenseData.licenseAddition.indexOf(file.url);
      this.storeFrom.licenseData.licenseAddition.splice(i, 1);
    },
    //营业执照
    licenseImg(img) {
      this.rules.licenseData = [
        {
          required: true,
          message: "请上传营业执照",
        },
      ];
      if (img.data != "") {
        this.rules.licenseImg = [];
        this.$nextTick(() => {
          this.$refs["uploads"].clearValidate();
        });
      }
      // 获取上传图片url截取？后面加密
      this.storeFrom.licenseData.licenseImg = img.data.license.substring(
        0,
        img.data.license.lastIndexOf("?")
      );
      let { certNo, name, address, legalRepName } = img.data.licenseInfo;
      this.storeFrom.licenseData.certNo = certNo;
      this.storeFrom.licenseData.name = name;
      this.storeFrom.licenseData.address = address;
      this.storeFrom.licenseData.legalRepName = legalRepName;
    },
    //删除营业执照删除
    licenseRemove() {
      this.storeFrom.licenseData.licenseImg = "";
    },
    // 资质信息判断隐藏显示
    cervicaltrue(row) {
      let self = this;
      console.log(self);
      if (row === 2500) {
        self.cervical = false;
        self.storeFrom.licenseData.validTermStart = "长期";
        self.storeFrom.licenseData.validTermEnd = "长期";
      } else if (row === 2) {
        self.cervical = true;
        self.storeFrom.licenseData.validTermStart = "";
        self.storeFrom.licenseData.validTermEnd = "";
      } else if (row === 4) {
        self.cervical = true;
        self.storeFrom.licenseData.validTermStart = "长期";
        self.storeFrom.licenseData.validTermEnd = "长期";
      }
    },
    //营业期限显示隐藏
    businesstrue(row) {
      let self = this;
      self.business = !self.business;
      self.businesss = !self.businesss;
      if (row === 1) {
        self.storeFrom.licenseData.validTermEnd = "长期";
        self.storeFrom.licenseData.validTermStart = "";
      } else if (row === 2) {
        self.storeFrom.licenseData.validTermStart = "";
        self.storeFrom.licenseData.validTermEnd = "";
      }
    },
    getInfo() {
      let id = this.$route.query.id;
      // 获取详情
      storeDetail({
        id: id,
      }).then((res) => {
        console.log(res, 6868);
        this.storeFrom = res.data;
        this.storeFrom.tabeType='1'
        this.storeFrom.accountData.radio = res.data.accountData.accountType * 1

        if (this.storeFrom.licenseData.validityEnd == '长期') {
          this.storeFrom.licenseData.scope = 1
        }
        if (this.storeFrom.licenseData.validityEnd == '长期') {
          this.userIdCope = 1
        } else {
          this.userIdCope = 2
        }

        if (this.storeFrom.accountData.needBranch) {
          getwxCapitalProvinces().then((data) => {
            data.data.forEach((item) => {
              item.label = item.provinceName
              item.value = item.provinceName
            })

            this.subOptions = data.data
            this.storeFrom.accountData.subProvince = this.storeFrom.accountData.subProvince
            this.subCity = this.storeFrom.accountData.subCity

            if (this.storeFrom.accountData.subProvince !== '') {
              console.log(this.storeFrom.accountData.subProvinceCode);
              getwxCapitalCity({
                provinceCode: this.storeFrom.accountData.subProvinceCode,
              }).then((data) => {
                console.log(data, 68);
                data.data.forEach((item) => {
                  item.label = item.cityName
                  item.value = item.cityName
                })
                this.subCitylist = data.data
                console.log(this.subCitylist, 'fen');
              })
              getwxCapitalbranches({
                bankAliasCode: this.storeFrom.accountData.branchBankCode,
                cityCode: this.storeFrom.accountData.subCityCode
              }).then((data) => {
                data.data.forEach((item) => {
                  item.label = item.bankBranchName
                  item.value = item.bankBranchName
                })
                this.subBank = data.data
              })
            }
            this.storeFrom.accountData.branchBank = this.storeFrom.accountData.branchBank
            console.log(data, 4445);

          })
        }
        this.Start = this.storeFrom.licenseData.validTermStart;
        this.End = this.storeFrom.licenseData.validTermEnd;

        if (this.storeFrom.licenseData.scope != null) {
          this.storeFrom.licenseData.scope = Number(
            this.storeFrom.licenseData.scope
          );
        }
        this.storeFrom.licenseData.type == null
          ? (this.storeFrom.licenseData.type = 2500)
          : this.storeFrom.licenseData.type;

        this.fileListFront = [];
        this.fileListInner = [];
        this.fileListFront.push({
          url: this.storeFrom.frontImg,
        });
        this.fileListInner.push({
          url: this.storeFrom.innerImg,
        });

        console.log(this.storeFrom.frontImg, this.storeFrom.innerImg, '身份证图片');

        if (this.storeFrom.licenseData.licenseAddition != null) {
          this.storeFrom.licenseData.licenseAddition.forEach(async (item) => {
            let { data } = await uploadGetStsUrl({ filename: item });
            this.fileListAddition = [];
            this.fileListAddition.push({
              url: data,
            });
          });
        }
        console.log(this.storeFrom.licenseData.cardFrontSide, this.storeFrom.licenseData.cardReverseSide, 'li');
        //营业执照
        let licenseImg = this.storeFrom.licenseData.licenseImg;
        // 身份证面部
        let cardFrontSide = this.storeFrom.licenseData.cardFrontSide;
        console.log(cardFrontSide);
        //身份证反面
        let cardReverseSide = this.storeFrom.licenseData.cardReverseSide;

        //身份证面部
        console.log(cardFrontSide, "身份证");
        uploadGetStsUrl({filename: cardFrontSide,}).then((res) => {
          res.url = res.data
          res.name = '正面'
          this.sideFileList = [];
          this.sideFileList.push(res)
          uploadGetStsUrl({filename: cardReverseSide,}).then((res) => {
            res.url = res.data
            res.name = '反面'
            this.sideFileList.push(res)
            this.$keyMap.set('imageList', this.sideFileList)
            console.log(this.sideFileList, 'list数据');
          });
        });
        //身份证反面

        //营业执照
        uploadGetStsUrl({
          filename: licenseImg,
        }).then((res) => {
          this.fileListLicense = [];
          this.fileListLicense.push({
            url: res.data,
          });
        });
      });
    },
    changeLicense(file, fileList) {
      this.licenseImgNum = file;
    },
    deleteUserimg() { },
    //删除
    handleRemove(file, fileList) {
      console.log(fileList, length);
      if (fileList.length < 1) {
        this.upName = "frontImg";
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    exceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，共选择了 ${files.length + fileList.length
        } 个文件，请删除重新上传`
      );
    },
    //门头照
    frontImg(img) {
      let self = this;
      self.storeFrom.frontImg = img.data;
    },
    //店内照
    innerImg(img) {
      let self = this;
      self.storeFrom.innerImg = img.data;
    },
    //身份证有效期
    cardNoStatus(row) {
      // console.log(row,this);
      // let self = this;
      // self.cardNoperiods = !self.cardNoperiods;
      // self.cardNoperiodss = !self.cardNoperiodss;
      // if (row === 1) {
      //   self.storeFrom.licenseData.validityEnd = "长期";
      // } else if (row === 2) {
      //   self.storeFrom.licenseData.validityEnd = "";
      // }
    },
    //上传图片个数为2个走submit提交
    async checkFile(file, fileList) {
      console.log(file, fileList, 66);
      console.log(this.idcardFile, "url");
      if (fileList.length == 1) {
        this.upName = "reverseImg";
      } else if (fileList.length < 1) {
        this.upName = "frontImg";
      }
      // console.log(  fileList[0].response.data.front,  fileList[1].response.data.front,'图片路径');
      if (fileList.length == 2) {
        // console.log(  fileList[0].response.data.front,  fileList[1].response.data.front,'图片路径');
        this.$keyMap.set('imageList', fileList)
        // this.storeFrom.licenseData.cardFrontSide =
        //   fileList[0].response.data.front;
        // this.storeFrom.licenseData.cardReverseSide =
        //   fileList[1].response.data.front;
        //   this.storeFrom.accountData.cardFrontSide =
        //   fileList[0].response.data.front;
        // this.storeFrom.accountData.cardReverseSide =
        //   fileList[1].response.data.front;
      }

      // if (count === 2) {
      //   this.$refs.upload.submit();
      // }
      // this.rules.file = [
      //   {
      //     required: true,
      //     message: "请上传身份证正反面",
      //   },
      // ];
      // if (this.submitFile.arrFile.length != 0) {
      //   this.rules.file = [];
      //   this.$nextTick(() => {
      //     this.$refs["uploadElement"].clearValidate();
      //   });
      // }
      // //  身份证正反面
      //  this.storeFrom.accountData.cardFrontSide = frontImg;
      //     this.storeFrom.accountData.cardReverseSide = reverseImg;
      //     //识别图片信息
      //     this.idcardInfo = res.data.idcardInfo;
      //     this.storeFrom.accountData.realName = this.idcardInfo.name;
      //     this.storeFrom.accountData.cardNo = this.idcardInfo.idNo;
      //     var res = this.idcardInfo.validityPeriod;

      //     let identitystor = res.substring(0, res.indexOf("-"));
      //     let identityend = res.substring(21, res.lastIndexOf("-") + 1);
      //     let str = res.substring(res.lastIndexOf("长"));
      //     if (str === "长期") {
      //       this.cardNoperiod = 1;
      //       this.cardNoperiods = false;
      //       this.cardNoperiodss = true;
      //       this.storeFrom.accountData.validityStart = identitystor;
      //     } else {
      //       this.cardNoperiod = 2;
      //       this.cardNoperiods = true;
      //       this.cardNoperiodss = false;
      //       this.storeFrom.accountData.validityStart = identitystor;
      //       this.storeFrom.accountData.validityEnd = identityend;
      //     }
    },
    //上传图片个数超过了limit最大数给出提示
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 2 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length
        } 个文件`
      );
    },
    //上传文件大于两个阻止 两张就走接口
    uploadFile(param) {
      console.log(param, "身份证");
      this.submitFile.arrFile.push(param.file);

      if (this.submitFile.arrFile.length != 2) {
        return;
      }

      let formData = new FormData();
      console.log(JSON.parse(this.submitFile.arrFile[0]), 5656);

      formData.append("frontImg", this.submitFile.arrFile[0]);
      formData.append("reverseImg", this.submitFile.arrFile[1]);

      ocrIdcard(formData).then((res) => {
        let frontImg = res.data.front.substring(
          0,
          res.data.front.lastIndexOf("?")
        );
        let reverseImg = res.data.reverse.substring(
          0,
          res.data.reverse.lastIndexOf("?")
        );
      });
    },
    //店铺添加表单验证
    storeAdd(storeFrom) {
      console.log(this.storeFrom.tabeType,585);
      console.log(storeFrom);
      console.log(this.$keyMap.get('imageList'), this.storeFrom.licenseData.cardReverseSide, 'tu');
      // console.log(this.$keyMap.get('imageList')[0].response.data.front.split("?"),'截取');
      if (this.$keyMap.get('imageList')[0].response !== undefined) {
        this.storeFrom.licenseData.cardFrontSide =
          this.$keyMap.get('imageList')[0].response.data.front.split("?")[0];
        this.storeFrom.licenseData.cardReverseSide =
          this.$keyMap.get('imageList')[1].response.data.reverse.split("?")[0];
        this.storeFrom.accountData.cardFrontSide =
          this.$keyMap.get('imageList')[0].response.data.front.split("?")[0];
        this.storeFrom.accountData.cardReverseSide =
          this.$keyMap.get('imageList')[1].response.data.reverse.split("?")[0];
      }

      // this.rules.file = [
      //   {
      //     required: true,
      //     message: "请上传身份证正反面",
      //   },
      // ];
      if (this.submitFile.arrFile.length != 0) {
        this.rules.file = [];
        this.$nextTick(() => {
          this.$refs["uploadElement"].clearValidate();
        });
      }
      this.rules.licenseImg = [
        {
          required: true,
          message: "请上传营业执照",
        },
      ];
      // if (this.storeFrom.licenseData.licenseImg != "") {
      //   this.rules.licenseImg = [];
      //   this.$nextTick(() => {
      //     this.$refs["uploads"].clearValidate();
      //   });
      // }
      this.$refs[storeFrom].validate((valid) => {
        console.log(valid,55858);
        if (valid == true) {
          console.log(this.storeFrom, '提交表单');
          storeEdit(this.storeFrom).then((res) => {
            console.log(res, '提交');
            if (res.status === 10000 && res.data === true) {
              this.$router.push("/store/list");
            }
          });
        }
      });
    },
  },
  computed: {
    //图片上传请求头部
    headers() {
      return {
        Authorization: getStore("token"),
      };
    },
  },
};
</script>
<style lang="less" scoped>
i {
  margin-left: 0px;
}

@import "../../assets/css/overall.less";
</style>
<style lang="less">
.itemPut {
  margin-left: 40px;
}

.el-upload--picture-card {
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  width: 146px;
  height: 146px;
  line-height: 146px;
  vertical-align: top;
}

.headline {
  font-size: 16px;
  color: #333333;
  font-weight: bold;
}

.el-form-item__label {
  text-align: left;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}

.text {
  font-size: 14px;
}

.text.item:hover {
  background-color: #f0f0f0;
}
</style>
